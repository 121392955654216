<template>
  <div class="main">
    <div class="expiring-password">
      <div class="expiring-top">{{ $t('warmTips') }}</div>
      <div class="expiring-content" v-html="$t('endTime', { time: pwdRemainDays })"></div>
      <div class="opearate-main">
        <aui-button type="danger" class="modify-password" @click="resetPwd">{{ $t('modifyNow') }}</aui-button>
        <aui-button class="back-login-btn" @click="backLogin">{{ $t('doNotmodifyNow') }}</aui-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@aurora/vue'
import { getQueryValue, getUrlStr } from '@/utils/utils';

export default {
  components: {
    AuiButton: Button
  },
  data() {
    return {
      pwdRemainDays: this.$route.query.pwdRemainDays || 0,
      callbackUrl: getQueryValue('callbackUrl') || ''
    }
  },
  methods: {
    resetPwd() {
      const { tenantId, uid, urlType } = this.$route.query

      const query = { type: 1, tenantId, uid, urlType, service: getQueryValue('service') }
      const queryString = getUrlStr(query);
      this.$router.push(`/resetPwd?${queryString}`)
    },
    backLogin() {
      window.localStorage && window.localStorage.setItem('hisPage', 'w3')
      location.href = decodeURIComponent(this.callbackUrl) 
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.main {
  width: 100%;
  .expiring-password {
    margin: 80px auto 0 auto;
    width: 530px;
    padding: 60px 50px 80px 50px;
    background: #fff;
    .expiring-top {
      text-align: center;
      font-size: 30px;
    }
    .expiring-content {
      font-size: 14px;
      margin-top: 26px;
      padding: 0 10px;
    }
    .opearate-main {
      text-align: center;
      margin-top: 30px;
      & > button {
        float: left;
        width: 100%;
        min-width: 100%;
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        & + button {
          margin-left: 0;
          margin-top: 20px;
        }
      }
      &::after {
        content: '';
        display: block;
        clear: both;
      }
      .back-login-btn {
        color: #f66f6a;
        background: #f2f2f2;
        border-color: transparent;
      }
      .modify-password {
        background: #ea9fa3;
        border-color: #ea9fa3;
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .main {
    .expiring-password {
      width: 100%;
      padding: 60px 24px 80px 24px;
      .opearate-main {
        margin: 30px auto 0 auto;
        width: 100%;
        max-width: 100%;
        > button {
          float: left;
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
</style>